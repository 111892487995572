import VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithHiddenTextSkinComponent from '@wix/thunderbolt-elements/src/components/VerticalAnchorsMenu/viewer/skinComps/VerticalAnchorsMenuSymbolWithHiddenTextSkin/VerticalAnchorsMenuSymbolWithHiddenTextSkin.skin';


const VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithHiddenTextSkin = {
  component: VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithHiddenTextSkinComponent
};


export const components = {
  ['VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithHiddenTextSkin']: VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithHiddenTextSkin
};


// temporary export
export const version = "1.0.0"
